import React, { useState } from 'react'

import { Box, Switch } from '@te-digi/styleguide'

import { useLorem } from '../../lorem'

const SwitchExample2 = () => {
  const [checked, setChecked] = useState(false)
  const lorem = useLorem({ sentences: 5 })

  return (
    <>
      {/* EXAMPLE_START */}
      <Box
        accessory={
          <Switch
            checked={checked}
            checkedText="Julkaistaan"
            onChange={setChecked}
            uncheckedText="Ei julkaista"
          />
        }
        borderColor={checked ? 'secondary' : undefined}
        color="light"
        noMargin
      >
        {lorem}
      </Box>
      {/* EXAMPLE_END */}
    </>
  )
}

export { SwitchExample2 }
