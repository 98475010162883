import React from 'react'

import { Box, Button, Heading, Link, Paragraph } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { Section } from '../../components/Section'
import { SwitchExample2 } from '../../examples/react/SwitchExample2'
import { useLorem } from '../../lorem'
import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => {
  const paragraph = useLorem({ sentences: 5 })
  const heading = useLorem({ words: 5 })

  return (
    <Content
      heading="Box"
      components={[{ component: Box, restElement: true }]}
      status={[{ type: 'accessible', version: '13.10.0' }]}
    >
      <Section>
        <Paragraph>
          Laatikon reunus, taustaväri, täytealue, kulmat ja varjo ovat
          vaihdeltavissa Style Guiden tarjoamien vaihtoehtojen mukaan.
        </Paragraph>
        <Playground>
          <Box noMargin>
            <Paragraph noMargin>{paragraph}</Paragraph>
          </Box>
        </Playground>
        <Playground format="html">
          <div className="box mb-0">
            <p className="mb-0">{paragraph}</p>
          </div>
        </Playground>
      </Section>
      <Section title="Taustavärin vaihto">
        <Paragraph>
          Oletuksena <Code>Box</Code>-komponentin tausta on valkoinen.
          Taustavärin voi vaihtaa käyttämällä <Code>color</Code> proppia arvolla{' '}
          <Code>light</Code>. Valkoinen on tarkoitettu luettavalle sisällölle ja{' '}
          <Code>light</Code> interaktiiviselle sisällölle.
        </Paragraph>
        <Playground>
          <Box
            color="light"
            noMargin
          >
            <Paragraph noMargin>{paragraph}</Paragraph>
          </Box>
        </Playground>
        <Playground format="html">
          <div className="box bg-light mb-0">
            <p className="mb-0">{paragraph}</p>
          </div>
        </Playground>
      </Section>
      <Section title="Varjo">
        <Paragraph>
          Komponentille on mahdollista lisätä varjo määrittämällä{' '}
          <Code>shadow</Code> propille arvo <Code>default</Code> tai{' '}
          <Code>modal</Code>. Näistä suositeltua on käyttää <Code>default</Code>
          -arvoa; <Code>modal</Code> on tarkoitettu sisäiseen käyttöön
          esimerkiksi <Code>Modal</Code> tai <Code>Notifications</Code>{' '}
          komponenteissa.
        </Paragraph>
        <Playground>
          <Box
            noMargin
            shadow="default"
          >
            <Paragraph noMargin>{paragraph}</Paragraph>
          </Box>
        </Playground>
        <Playground format="html">
          <div className="box mb-0 shadow-default">
            <p className="mb-0">{paragraph}</p>
          </div>
        </Playground>
      </Section>
      <Section title="Täytealueen vaihto">
        <Paragraph>
          Padding voidaan asettaa samansuuruiseksi kaikille reunoille
          käyttämällä proppia <Code>padding</Code>. Eri suuruiset paddingit
          voidaan asettaa käyttämällä proppeja <Code>paddingTop</Code>,{' '}
          <Code>paddingRight</Code>, <Code>paddingBottom</Code> ja{' '}
          <Code>paddingLeft</Code>. Paddingin arvona voi olla jokin{' '}
          <a href="/design-tokens/#space">space-tokenin arvoista</a> tai
          objekti, jossa on eri breakpointeille määritelty omat space-arvot.
          Paddingin default-arvo on objekti <Code>{'{'}</Code>
          <Code>xs: 'md', md: 'xl'</Code>
          <Code>{'}'}</Code>, jossa xs-näyttökoossa paddingina on arvo{' '}
          <Code>md</Code> ja md-näyttökoosta ylöspäin padding on kokoa{' '}
          <Code>xl</Code>.
        </Paragraph>

        <Playground>
          <Box
            padding="md"
            noMargin
          >
            <Paragraph noMargin>{paragraph}</Paragraph>
          </Box>
        </Playground>

        <Playground>
          <Box
            paddingTop="md"
            noMargin
          >
            <Paragraph noMargin>
              Vain yläpadding asetettu. Huomaa, että kaikkien muiden paddingien
              (oikea, ala, vasen) arvo on tällöin 0.
            </Paragraph>
          </Box>
        </Playground>

        <Playground>
          <Box
            paddingBottom={{ xs: 'md', md: 'xl' }}
            paddingLeft={{ xs: 'md', md: 'xl' }}
            paddingRight={{ xs: 'md', md: 'xl' }}
            noMargin
          >
            <Paragraph noMargin>
              Yläpadding asettuu nollaksi, kun muut arvot asetetaan defaultiksi.
            </Paragraph>
          </Box>
        </Playground>

        <Playground>
          <Box
            paddingTop="sm"
            paddingBottom="lg"
            paddingLeft="xl"
            paddingRight="md"
            noMargin
          >
            <Paragraph noMargin>
              Kaikki padding-arvot asetettu eri suuruisiksi. Huomaa, että
              jokainen arvo tulee asettaa erikseen, shorthandia (
              <Code>padding: 1px 2px 3px 4px</Code>) ei voi käyttää.
            </Paragraph>
          </Box>
        </Playground>

        <Playground format="html">
          <div className="box p-md mb-0">
            <p className="mb-0">{paragraph}</p>
          </div>
        </Playground>
      </Section>
      <Section title="Lisävarusteella">
        <Playground example={SwitchExample2} />
      </Section>
      <Section title="Maksimikorkeuden asettaminen">
        <Playground>
          <Box
            height={200}
            overflow="auto"
            noMargin
          >
            <Paragraph>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
              posuere interdum sem. Quisque ligula eros ullamcorper quis,
              lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu.
              Sed arcu lectus auctor vitae, consectetuer et venenatis eget
              velit. Sed augue orci, lacinia eu tincidunt et eleifend nec lacus.
            </Paragraph>
            <Paragraph noMargin>
              Donec ultricies nisl ut felis, suspendisse potenti. Lorem ipsum
              ligula ut hendrerit mollis, ipsum erat vehicula risus, eu suscipit
              sem libero nec erat. Aliquam erat volutpat. Sed congue augue vitae
              neque. Nulla consectetuer porttitor pede. Fusce purus morbi tortor
              magna condimentum vel, placerat id blandit sit amet tortor.
            </Paragraph>
          </Box>
        </Playground>
      </Section>
      <Section title="Muita käyttökohteita">
        <Playground>
          <Box noMargin>
            <Heading
              level={2}
              size={4}
            >
              <Link as={LinkMock}>{heading}</Link>
            </Heading>
            <Paragraph noMargin>{paragraph}</Paragraph>
          </Box>
        </Playground>
        <Playground format="html">
          <div className="box mb-0">
            <h2 className="h4">
              <Link as={LinkMock}>{heading}</Link>
            </h2>
            <p className="mb-0">{paragraph}</p>
          </div>
        </Playground>
        <Playground>
          <Box noMargin>
            <Heading
              level={2}
              size={4}
            >
              {heading}
            </Heading>
            <Paragraph>{paragraph}</Paragraph>
            <Button as={LinkMock}>Lorem ipsum</Button>
          </Box>
        </Playground>
        <Playground format="html">
          <div className="box mb-0">
            <h2 className="h4">{heading}</h2>
            <p>{paragraph}</p>
            <ButtonHTMLExample as={LinkMock}>Lorem ipsum</ButtonHTMLExample>
          </div>
        </Playground>
      </Section>
      <Section title="Saavutettavuus">
        <Paragraph>
          Huomioi, että padding vaikuttaa luettavuuteen. Jos <Code>Box</Code>in{' '}
          <Code>padding</Code> asetetaan nollaksi, pitää sisällössä olla
          tarvittava padding.
        </Paragraph>
      </Section>
    </Content>
  )
}

export default Page
